import {
  COMMENT_READ_REASON,
  PERMISSIONS,
  PLAN_STATUS,
  USER_CATEGORY,
  WRAPPER_STATUS,
} from 'appConstants';
import isURL from 'validator/lib/isURL';
import { LanguageContext } from 'components/Language/Context';
import { useContext } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { ICommentSummary, PlanWrapperSummary, Profile } from 'types';
import { PDFValuesProps } from 'features/Reports/Export/type';
import requiredInputs from './features/OrganizationDetails/ProducerData/Forms/hooks/requiredInputs.json';

export const isValidEmail = (val: string) => {
  const isSamsungBrowser = navigator.userAgent.match(
    /SAMSUNG|Samsung|samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
  );
  if (!isSamsungBrowser) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
  } else {
    const split = val.split('@');
    if (split.length !== 2) return false;
    if (split[1].indexOf('.') < 2) return false;
    return true;
  }
};

export const isPasswordMatch = (val1: string, val2: string) => {
  return val1 === val2;
};

export const fdate = (s: string | number | Date) => {
  const x = new Date(s);
  if (!x || !s) return '';
  const d = `${x.getDate().toString().padStart(2, '0')}/${(x.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${x.getFullYear()}`;
  return d;
};

export const fdate2 = (s: string | number | Date) => {
  const x = new Date(s);
  if (!x || !s) return '';
  const d = `${x.getUTCFullYear()}-${(x.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${x.getDate().toString().padStart(2, '0')}`;
  return d;
};

export const clone = (toClone: any) => {
  return JSON.parse(JSON.stringify(toClone));
};

export const statusColor = (status: number): string => {
  switch (status) {
    case PLAN_STATUS.Approved:
      return 'green';
    case PLAN_STATUS.Draft:
      return 'orange';
    case PLAN_STATUS.Deleted:
      return 'var(--error-color)';
    default:
      return 'blue';
  }
};

export const numericFormat = (date, time?: string) => {
  if (!date) return;
  let newDate = new Date(date);
  let day: any = newDate.getDate();
  let month: any = newDate.getMonth() + 1;
  let year: any = newDate.getFullYear();
  //add an extra zero in front of day and month if it's single digits
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;
  if (time) return new Date(`${year}-${month}-${day}T23:59:59Z`).toString();
  return `${year}-${month}-${day}`;
};

export const FormatDate = (date): string => {
  // const { messages } = useContext(LanguageContext);
  if (!date) return '';

  date = new Date(date);
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hours = date.getHours();
  var mins = date.getMinutes();
  var secs = date.getSeconds();

  if (day < 10) day = `0${day}`;
  if (hours < 10) hours = `0${hours}`;
  if (mins < 10) mins = `0${mins}`;
  if (secs < 10) secs = `0${secs}`;

  return `${day} ${monthNames[monthIndex]} ${year}`;

  // return `${day} ${messages[monthNames[monthIndex]]} ${year}`;
};

const cache = createIntlCache();
export function CommaFormatted(amount) {
  var defaultLocale = window?.localStorage.getItem('locale') || 'en';
  const intl = createIntl(
    {
      locale: defaultLocale,
      messages: {},
    },
    cache
  );
  if (isNaN(amount)) {
    return 0;
  } else {
    return intl.formatNumber(amount);
  }
}

export function RemovePreFilledZeroInInputs(data) {
  if (data === undefined || data === null) {
    return '';
  } else {
    return CommaFormatted(data);
  }
}

export const dataToBase64 = (
  dataBlob: File | Blob,
  returnType: 'base64' | 'dataURL'
) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(dataBlob);
    fileReader.onerror = () => reject(fileReader.error);
    fileReader.onload = () => {
      const base64Part = (fileReader.result as string)
        .replace('data:', '')
        .replace(/^.+,/, '');
      return returnType === 'dataURL'
        ? resolve(fileReader.result)
        : resolve(base64Part);
    };
  });
};

export const isValidURL = (str: string): boolean => {
  const options: any = {
    protocols: ['http', 'https', 'www'],
    require_tld: true,
    require_protocol: false,
    require_host: true,
    require_valid_protocol: true,
    allow_underscores: true,
    host_whitelist: false,
    host_blacklist: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
    disallow_auth: false,
  };
  return isURL(str, options);
};

/**
 * Make Text safe to export in a CSV.
 */
export const csvSafeText = (text: string) => {
  if (!text || !text.length) return null;
  const whiteSpaceRegex = /[,;\r\n\t]/gi;
  const quotesRegex = /"/gi;
  return text.replace(whiteSpaceRegex, ' ').replace(quotesRegex, "'");
};

export const validateFileSize = (file) => (file?.size <= 5242880); // 5242880 Bytes (in binary) === 5 MB

export const validateFileSizeRange = (file: File, minSizeMB: number, maxSizeMB: number): boolean => {
  // Convert the minimum size from megabytes to bytes (1 MB = 1024 * 1024 bytes)
  const minSizeBytes = minSizeMB * 1024 * 1024;

  // Convert the maximum size from megabytes to bytes
  const maxSizeBytes = maxSizeMB * 1024 * 1024;

  // Check if the file's size is between the minimum and maximum size in bytes
  return file?.size >= minSizeBytes && file?.size <= maxSizeBytes;
};

export const validateFileFormat = (file, format = 'image') => {
  /*
    Refer to the following link
    for common MIME types.
    https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  */
  const mimeTypes = {
    png: 'png', // .png
    jpeg: 'jpeg', // .jpeg
    jpg: 'jpg', // .jpg
    gif: 'gif', // .gif
    pdf: 'pdf', // .pdf
    csv: 'csv', // .csv
    rtf: 'rtf', // .rtf
    doc: 'msword', // .doc
    docx: 'vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    xls: 'vnd.ms-excel', // .xls
    xlsx: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    ppt: 'vnd.ms-powerpoint', // .ppt
    pptx: 'vnd.openxmlformats-officedocument.presentationml.presentation' // .pptx
  };
  const allowedFormats = {
    image: [ mimeTypes.png, mimeTypes.jpeg, mimeTypes.jpg, mimeTypes.gif ],
    doc: [ mimeTypes.pdf, mimeTypes.csv, mimeTypes.rtf, mimeTypes.doc, mimeTypes.docx, mimeTypes.xls, mimeTypes.xlsx, mimeTypes.ppt, mimeTypes.pptx ],
    file: [ mimeTypes.png, mimeTypes.jpeg, mimeTypes.jpg, mimeTypes.gif, mimeTypes.pdf, mimeTypes.csv, mimeTypes.rtf, mimeTypes.doc, mimeTypes.docx, mimeTypes.xls, mimeTypes.xlsx, mimeTypes.ppt, mimeTypes.pptx ]
  };
  const fileType = file.type.split('/').pop();
  const isValidFile = (allowedFormats[format]?.includes(fileType) ?? false);
  return isValidFile;
};

export const getMonthFromDate = (date) => {
  const newDate = new Date(date);
  return newDate.getMonth() + 1;
};

export const getMonthName = (month: number | string) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[Number(month) - 1];
};

export const truncateFileNames = (name: string) => {
  if (!name) return '';
  const split = name.split('.');
  return `${split[0].slice(0, 70)}.${split[1]}`;
};

export function whichBrowser() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
}

/**
 * This function is only valid under
 * the context of: detectUserCategory()
 */
const melOrFs = (permissions) => {
  if (
    permissions.includes(PERMISSIONS.QUANTITATIVE_REVIEW) &&
    permissions.includes(PERMISSIONS.QUALITATIVE_REVIEW)
  ) {
    return USER_CATEGORY.MEL;
  }
  return USER_CATEGORY.FS;
};

/**
 * THIS CATEGORY IS ONLY VALID UNDER THE CONTEXT
 * OF A PARTICULAR WRAPPER
 */
export function detectUserCategory(
  wrapperSummary: PlanWrapperSummary,
  permissions: Profile['permissions']
) {
  let category: USER_CATEGORY | null = null;
  const { status: wrapperStatus, canEdit } = wrapperSummary;
  if (wrapperStatus === WRAPPER_STATUS.QuantitativeReview && canEdit) {
    category = USER_CATEGORY.MEL;
  } else if (
    (wrapperStatus === WRAPPER_STATUS.QualitativeReview && canEdit) ||
    (wrapperStatus === WRAPPER_STATUS.POReview && !canEdit)
  ) {
    category = melOrFs(permissions);
  } else {
    category = USER_CATEGORY.PO;
  }
  return category;
}

export interface IAreCommentsRead {
  read: boolean;
  reason: null | COMMENT_READ_REASON;
}

export function areCommentsRead(
  commentSummary: ICommentSummary
): IAreCommentsRead {
  const { total, resolved, readUnresolved } = commentSummary;
  let unreadComments: number = 0;
  unreadComments = total - resolved - readUnresolved;
  let commentStatus: IAreCommentsRead = {
    read: false,
    reason: null,
  };
  if (total) {
    if (unreadComments === 0) {
      commentStatus.read = true;
      commentStatus.reason = COMMENT_READ_REASON.ALL_READ;
    } else if (resolved === total) {
      commentStatus.read = true;
      commentStatus.reason = COMMENT_READ_REASON.ALL_RESOLVED;
    }
  }
  return commentStatus;
}

/**
 * Returns a Promise that resolves after a specified delay.
 * @param ms - The number of milliseconds to wait before resolving. The default value is 500ms.
 * @returns A Promise that resolves after the specified delay.
 */
export function timeout(ms: number | undefined = 500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Helper function to check if a value should be excluded
function isValidValue(value: any): boolean {
  if (typeof value !== 'string') return false;
  const trimmedValue = value.trim();

  // Sanitize: values that should be excluded from translation
  const patterns = [
    /^https?:\/\/[^\s]+$/, // URLs
    /^[\dA-Fa-f]{8}-[\dA-Fa-f]{4}-[\dA-Fa-f]{4}-[\dA-Fa-f]{4}-[\dA-Fa-f]{12}$/, // UUIDs
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?(?:[+-]\d{2}:\d{2}|Z)?$/, // ISO 8601 Date
    /^\d{4}-\d{2}-\d{2}$/, // Short Date (YYYY-MM-DD)
    /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)?$/, // Numeric values
    /^\d{4}(?:\/\d{4})?$/, // Year or Year Range
    /^\d+[-/]\d+$/, // Numeric Intervals
    /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/, // emails
    /^(application|image|audio|video)\/[\w.-]+$/, // content types
    /\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/, // image file names
    /^pp\d{3}$/, // Product Code
    /^\d{1,3}(?:,\d{3})*$/, // comma-separated numbers
    /^\d{1,3}(?:,\d{3})*\s[A-Za-z\s]+$/, // Detect text numbers with units
    /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/, // base64
    /^data:(application|image|audio|video)\/[a-zA-Z0-9.-]+;base64,[A-Za-z0-9+/]+={0,2}$/ // base64-encoded string
  ];

  return (
    trimmedValue !== '' &&
    !patterns.some(pattern => pattern.test(trimmedValue)) &&
    trimmedValue.length > 1 // Allow strings with more than one character
  );
}

// Function to extract keys and values from a nested object
export function extractKeysAndValues<T>(obj: T): { keys: string[]; values: string[] } {
  const keys: string[] = [];
  const values: string[] = [];
  const seen = new Set<string>();

  function traverse(object: any, path: string[] = []): void {
    if (typeof object !== 'object' || object === null) {
      return;
    }

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const value = object[key];
        const newPath = [...path, key];
        const currentPath = newPath.join('.');

        if (!excludedPaths.has(currentPath)) {
          if (currentPath.startsWith('staticText.')) {
            // Special handling for staticText: include all non-empty string values
            if (typeof value === 'string' && value.trim() !== '') {
              addKeyValue(currentPath, value);
            }
          } else if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                  traverse(item, [...newPath, index.toString()]);
                } else if (isValidValue(item)) {
                  addKeyValue([...newPath, index.toString()].join('.'), item);
                }
              });
            } else {
              traverse(value, newPath);
            }
          } else if (isValidValue(value)) {
            addKeyValue(currentPath, value);
          }
        }
      }
    }
  }

  function addKeyValue(path: string, value: string): void {
    if (!seen.has(path)) {
      seen.add(path);
      keys.push(path);
      values.push(value);
    }
  }

  traverse(obj);
  return { keys, values };
}

// Function to rebuild the object with translated values and preserve other types
export function rebuildObject<T>(original: T, keys: string[] = [], translatedValues: string[]): T {
  const result = { ...original };

  keys.forEach((key, index) => {
    const path = key.split('.');
    let current = result;

    path.forEach((segment, i) => {
      if (i === path.length - 1) {
        current[segment] = translatedValues[index]; // Assign the translated value
      } else {
        if (!current[segment]) {
          current[segment] = isNaN(Number(path[i + 1])) ? {} : []; // Determine if next is object or array
        }
        current = current[segment];
      }
    });
  });

  return result as T;
}

// Define the paths you want to exclude from translation
const excludedPaths: Set<string> = new Set([
  'staticText.floid',
  'staticText.facebook',
  'staticText.linkedin',
  'staticText.instagram',
  'locale.fromLocale',
  'locale.toLocale',
  'details.pnIcon',
  'details.createdAsOf',
  'details.givenYear',
  'details.format',
  'details.exportName',
  'details.shareWithFT',
  'details.orgType',
  'atAGlance.producerLogo',
  'atAGlance.name',
  'contactUs.commercialContactName',
  'contactUs.email',
  'contactUs.website',
  'contactUs.phoneNumber',
  'contactUs.phoneNumberIsOnWhatsApp',
  'contactUs.facebook',
  'contactUs.linkedin',
  'contactUs.facebook',
  'contactUs.instagram',
  'products.productImage',
  'products.productCode',
]);

export const extractAlertKeys = (obj) => {
  let alertKeys: string[] = [];
  function traverse(subObj, parentKey = '') {
    for (let key in subObj) {
      if (typeof subObj[key] === 'object' && subObj[key] !== null) {
        if (subObj[key].type === 'alert') {
          alertKeys.push(key);
        }
        traverse(subObj[key], key);
      }
    }
  }
  traverse(obj);
  return alertKeys;
};

// Reconstruct fields to add previous value and year for Producer data
export const reconstructFields = (currFields, prevFields, customRequiredInputs?: Record<string, any>) => {
  const mergeUniqueByName = (currFields, prevFields) => [
    ...new Map([...prevFields, ...currFields].map(f => [f.name, f])).keys()
  ];
    
  const allFields = mergeUniqueByName(currFields, prevFields);
  const mandatoryFields = extractAlertKeys(customRequiredInputs ?? requiredInputs);

  return allFields.map((key) => {
    const currField = currFields.find(({ name }) => name === key) || {};
    const prevField = prevFields.find(({ name }) => name === key) || {};
    const date = currField.updatedAsOf || prevField?.updatedAsOf;
    const isMandatory = mandatoryFields.includes(key);

    let value = ((!currField?.updatedAsOf && !isMandatory) ? prevField?.value : currField?.value);

    if((key === 'volumes_produced' || key === 'volume_forecast') && !currField?.value) {
      const [, curr] = prevField?.value?.split('|') || [];
      value = `${curr}|,,`;
    }

    return {
      ...currField,
      name: key,
      updatedAsOf: date && fdate2(date),
      oldValue: value,
      value: value,
      prevValue: isMandatory ? prevField?.value : undefined,
      prevYear: prevField?.updatedAsOf
        ? new Date(prevField.updatedAsOf).getUTCFullYear()
        : undefined,
    };
  });
};